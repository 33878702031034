import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import VCard from './../components/vcard';
import { Link } from 'gatsby';
import Image from './../components/image';
import Caption from './../components/caption';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <div className="columns">
    <div className="column has-text-centered is-vcentered welcome">
        <h1>Willkommen</h1>
        <p>in der Praxis für kreative Lebensgestaltung!</p>
    </div>
    <div className="column">
        <VCard style={{
          marginTop: "1rem",
          height: "210px"
        }} alt="Wolfgang Engelhardt" title="Wolfgang Engelhardt" text={<>Terminvereinbarung unter:<br /><a href='tel:+436644533121'>+43 664 4533121</a></>} mdxType="VCard"></VCard>
    </div>
    </div>
    {
      /* Section 1 */
    }
    <section className="columns page-section">
    <div className="column image-column">
        <figure>
            <Image filename="orange.jpg" alt="Gezeichnete Orange" mdxType="Image" />
            <Caption mdxType="Caption">
                Metaphern (keine Symbole) dienen durch den Malprozess hindurch als neuer Container für bestimmte Gefühle. Sie werden in dieser Konstellation neu vom Gehirn abgespeichert. Infolge wird ein hierzu gehörender spezifischer Handlungsschritt unbelastet möglich.
            </Caption>
        </figure>
    </div>
    <div className="column is-two-thirds">
        <h2>
            Maltherapie mit Erwachsenen
        </h2>
        <p>
            Das Beharren auf Mustern und Gewohnheiten basiert auf Denkprozessen und Bildern der Vergangenheit. Die Speicherung (Erinnerung) erfolgt in Bildern, die meist mit Emotionen verknüpft sind. Unerwünschtes Verhalten (Störungen) werden so unbewußt und automatisch wiederholt.
        </p>
        <p>
            In der Maltherapie werden diese Bilder der auftauchenden Reihenfolge nach abgeholt und im Hier und Jetzt bearbeitet bzw. „in Ordnung“ gebracht. Die Bilder werden einfach und deutlich gemalt. Gefühle oder körperliche Symptome, die keine eigenen Bilder haben, werden mit Metaphern quasi eingefangen und können so bearbeitet werden.  Auch die hinter Flashbacks und Trigger stehenden emotional aufgeladenen Erfahrungen werden durch klare, einfache und neutrale Bilder „überschrieben“ und können so ihre emotionale Wirkung verlieren.  Beziehungsthemen werden mittels bestimmter Bilderfolgen bearbeitet.
        </p>
        <Link to="/maltherapie/" className="link-button" mdxType="Link">Infos zu Maltherapie →</Link>
    </div>
    </section>
    {
      /* Section 2 */
    }
    <section className="columns page-section">
    <div className="column is-two-thirds">
        <h2>
            Maltherapie mit Kindern
        </h2>
        <p>
            Mein therapeutischer Zugang zu Kindern basiert – nach 4 Jahren Maltherapie in einem Kinderdorf – auf einer von Gerald Hüther und Herbert Renz-Polster in ihrem Buch (<i>Wie Kinder heute wachsen</i>) dargestelltem Entwicklungskonzept.
        </p>
        <p>
            Die unverhandelbaren Quellen der Entwicklung von Kindern beruhen auf Freiheit, Unmittelbarkeit, Widerständigkeit und Bezogenheit zu Mitmenschen. Durch Maltherapie – mit ihren diversen Mitteln, Methoden und Materialien – kommen Kinder zu sich und heraus aus der Getriebenheit, Angst, Störung, landen im Moment und bei sich. Von hier aus sind neue Wege möglich.
        </p>
        <Link to="/maltherapie-mit-kindern/" className="link-button" mdxType="Link">Maltherapie mit Kindern →</Link>
    </div>
    <div className="column image-column">
        <Image filename="gemalte_hand.jpg" alt="Gemalte Hand" mdxType="Image" />
    </div>
    </section>
    {
      /* Section 3 */
    }
    <section className="columns page-section">
    <div className="column image-column">
        <Image filename="kugelfisch-v3.jpg" alt="Ein Kugelfisch sagt: Das Problem hat damit zu tun, wie du urteilst! Die Wahrheit liegt hinter der Fassade." mdxType="Image" />
    </div>
    <div className="column is-two-thirds">
        <h2>
            Supervision
            mit systemisch kunsttherapeutischer Ausrichtung
        </h2>
        <p>
            Über bildnerische und kreative Mittel ist es möglich, neben dem Gespräch, eine neue Ebene hinzuzuziehen, um Zugang zu Lösungsstrategien und neuen Perspektiven im Berufsalltag zu bekommen. Systemische Elemente berücksichtigen das Denken und Handeln in vernetzten Bezügen. 
        </p>
        <ul>
            <li>Neue Sichtweisen entwickeln</li>
            <li>Erweiterte Handlungsspielräume erkennen</li>
            <li>Raum zur Selbstreflexion eröffnen</li>
        </ul>
        <p>
            Selbsterfahrung und Diplomarbeitsbegleitung sind mit kreativen Methoden und konstruktiver Konfrontation lösungsorientiert.
        </p>
        <Link to="/supervision/" className="link-button" mdxType="Link">Infos zu Supervision →</Link>
    </div>
    </section>
    {
      /* Section 4 */
    }
    <section className="columns page-section">
    <div className="column is-two-thirds">
        <h2>
            Lebenskrisen bewältigen
        </h2>
        <p>
            Ohne Krise geht es nicht! Das noch nicht Bekannte hat eine gewaltige Zugkraft und zieht uns wie ein Magnet an; auf der anderen Seite ist der Schritt aber ins Ungewisse mit Angst verbunden, weil wir den verlässlichen und sicheren Hafen verlassen müssen.
        </p>
        <p>
            Bildgebende Methoden docken direkt am Unterbewussten an und ermöglichen das Sichtbarmachen von Ursachen und Lösungen. Ein Leben aus der Mitte wird wahrheitsgetreuer und direkter möglich.
        </p>   
        <Link to="/krisenbewaeltigung/" className="link-button" mdxType="Link">Infos zu Krisenbewältigung →</Link>
    </div>
    <div className="column image-column">
        <figure>
            <Image filename="supervision.jpg" style={{
            maxHeight: "400px"
          }} imageStyle={{
            objectFit: 'contain'
          }} alt="Gemälde" mdxType="Image" />
            <Caption style={{
            maxWidth: "260px"
          }} mdxType="Caption">Wenn sich Himmel und Erde begegnen, entstehen ungeahnte, neue und kreative Wege.</Caption>
        </figure>
    </div>
    </section>
    {
      /* Section: Kontakt */
    }
    <section className="columns page-section">
    <div className="column is-full has-text-centered">
        <p>
            Einsicht gewinnen in Deinen Entwicklungsprozess:
        </p>
        <Link to="/kontakt/" className="link-button" mdxType="Link">Kontakt →</Link>
    </div>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      