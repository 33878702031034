import React from 'react';

import Layout from '../components/layout';
import Helmet from "../components/helmet";

import IndexContent from '../page_content/index.mdx';

const IndexPage = ({children}) => {

    return (
        <Layout>
            <Helmet/>
            <IndexContent />
            {children}
        </Layout>
    )
}

export default IndexPage;
